import React from 'react'
import { Container } from '@components'
import { TermsPage } from '@pages/components'
import { useWindowSize } from '@hooks'
import './TERMS.scss'

interface SellingGuideProps {}

const TERMS: React.FC<SellingGuideProps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])

  return (
    <Container>
      <div className={'banner-terms'}>
        <div className="banner-terms__title"> TourDay Golf</div>
        <div className="banner-terms__subtitle">Terms & Conditions</div>
        {isMobileRender && (
          <div className="banner-terms__description">
            Search through the extensive TourDay Golf catalog of golf equipment to find the club you want to buy.
          </div>
        )}
      </div>
      <TermsPage.TermsGuide isMobile={isMobileRender} />
    </Container>
  )
}

export default TERMS
